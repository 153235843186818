import React, { useEffect } from 'react'
import { Collapse, Divider } from 'antd'
import VegaChart from '../../components/VegaChart'
import Layout from '../../components/Layouts'
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import './../dataviz.css'

const { Panel } = Collapse

function callback(key) {
  console.log(key)
}

export const frontmatter = {
  title: `Not a Galaxy Cluster`,
  written: '2019-08-10',
  updated: '2018-08-10',
  layoutType: `post`,
  contentType: `dataviz`,
  path: `/not-galaxy/`,
  category: `Visualisation, Fun`,
  image: `./poster.png`,
  cover: `./header2.jpg`,
  description: `This is not a galaxy cluster, then what is it?`
}

const customPanelStyle = {
  background: 'cornsilk',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden'
}

// const codeImport = {
// `
// # imports
// import pandas as pd 
// from pandas_datareader import data 
// import altair as alt
// `
// }

// const codeBody = {`
// def get_data(ticker, start, end):
//     """ Download data and add daily returns """
//     df = data.get_data_yahoo(ticker, start, end)
//     df['returns'] = df['Adj Close'].pct_change()    
//     return df

// def make_altair_plot(data, title, q=[.05, .95], width=800, height=600):
//     """ Generates daily return scatter plot"""
//     data = data.reset_index()[['Date', 'returns']]
//     returns_chart = alt.Chart(data, width=100).mark_circle(size=8).encode(
//         x=alt.X('returns:Q', title='Daily Returns (%)', axis=alt.Axis(format='%'), scale=alt.Scale(domain=[-0.10, 0.10])),
//         y=alt.Y('jitter:Q', title=None, axis=None),
//         tooltip=[alt.Tooltip('Date:T'), alt.Tooltip('returns:Q', title='Return', format='%')],
//         color=alt.Color('returns:Q', scale=alt.Scale(scheme='blueorange'), title='Returns'),
//     ).transform_calculate(
//         jitter='sqrt(-2*log(random()))*cos(2*PI*random())'
//     ).properties(
//         width=width, height=height,
//         title=title
//     ).interactive()
//     # make lines
//     lines = data.quantile(q)['returns'].values
//     overlay = pd.DataFrame({'x1': lines})
//     vline = alt.Chart(overlay).mark_rule(color='#01DDFE', strokeWidth=2).encode(x='x1:Q')    
//     # assemble the chart
//     chart = returns_chart + vline    
//     return chart

//     # Get data & Plot
//     df = get_data('SPY', '1990-01-01', '2019-02-01')
//     make_altair_plot(df, 'S&P500 Returns between 1990-2019')    
// `}

export default function NLGDecoderVisualiser(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

    return (
      <div className="dataviz-page">
        <Layout data={props.data} location={props.location}>
          <div style={{ maxWidth: 800, margin: '0px auto' }}>
            <h1 className="header-title">Not A Galaxy Cluster</h1>
            <h4
              className="header-subtitle"
              style={{ marginTop: 20, marginBottom: 40 }}
            >
              10 AUGUST, 2019
            </h4>
            <div className="story-content">
              <p className="para" style={{ marginTop: 20, marginBottom: 40 }}>
                Can you guess what is it in the video, if not the galaxy
                cluster? 😉
              </p>
              <iframe
                style={{ marginBottom: 20, width: 640, height: 360 }}
                src="https://www.youtube.com/embed/fA3bnxga1R4"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <Collapse
              onChange={callback}
              bordered={false}
              style={{ marginBottom: 40 }}
            >
              <Panel header="REVEAL DETAILS" key="1" style={customPanelStyle}>
                <div style={{ margin: '0px auto' }}>
                  <p>
                    This plot was created using daily returns of SP500 from 1st
                    Jan, 1990 to 9th August, 2019. Comforting to see that 95% of
                    points lie between the +/- 2% (the blue vertical lines in
                    the plot). Although, 5th Aug 2019 😱 was tough on the
                    portfolio.
                  </p>
                  <div style={{ marginTop: 20, marginBottom: 20 }}>
                    <VegaChart
                      id="spy-returns"
                      chartName="spy-returns"
                      specUrl="https://gist.githubusercontent.com/katnoria/093847b7543e2ee0ac8fc5694bb3233d/raw/c700f3e6a57a49ccd5fa74020e937668a3a4b032/spy.json"
                    ></VegaChart>
                    <p
                      style={{
                        fontSize: 10,
                        textAlign: 'center',
                        marginBottom: 20
                      }}
                    >
                      Hover over the points to see the details
                    </p>

                    <Divider />
                    <p style={{ marginTop: 20, marginBottom: 20 }}>
                      Some more plots just for fun.
                    </p>
                    <b style={{ marginBottom: 20 }}>
                      The points between the blue vertical lines cover 90% of
                      the days
                    </b>

                    <VegaChart
                      id="aapl-returns"
                      chartName="aapl-returns"
                      specUrl="https://gist.githubusercontent.com/katnoria/d9b69948b4fcd443a6a6af63f5e7d177/raw/1d4802611d5a71985d51be3ea5af7b4eb96fdaa7/aapl_aug2019.json"
                    ></VegaChart>
                    <VegaChart
                      id="amzn-returns"
                      chartName="amzn-returns"
                      specUrl="https://gist.githubusercontent.com/katnoria/eaa115be118e067f5c31e47a15997637/raw/15f7cef0e531685ab3bdb51a8e23ebd602dcc2b3/amzn_aug2019.json"
                    ></VegaChart>
                    <VegaChart
                      id="fb-returns"
                      chartName="fb-returns"
                      specUrl="https://gist.githubusercontent.com/katnoria/9eb8a292c05aa9d30a025e3bcb36b4fb/raw/245ec8c3e64920a174394d9c53e501ac173b2251/fb_aug2019.json"
                    ></VegaChart>
                    <VegaChart
                      id="msft-returns"
                      chartName="msft-returns"
                      specUrl="https://gist.githubusercontent.com/katnoria/e040f6895e2c8337d5fbb1d4bedd1694/raw/c476a23291b91fa52fedb1a2742d9bae1fbf5867/msft_aug2019.json"
                    ></VegaChart>
                    <VegaChart
                      id="goog-returns"
                      chartName="goog-returns"
                      specUrl="https://gist.githubusercontent.com/katnoria/a469de784ec660d22fd68f1ae4d1b343/raw/3ea4b89ea7ebe17b22ed3e258b573defb45f8d7e/goog_aug2019.json"
                    ></VegaChart>
                  </div>
                  <p>
                    <b>Data</b>: Yahoo Finance, Adjusted Close
                  </p>
                  <p>
                    <b>Last Updated</b>: 10 August, 2019
                  </p>
                </div>
              </Panel>
              <Panel header="SHOW CODE" key="2" style={customPanelStyle}>
                <div>
                  <pre>
                  <code className="language-python">                
                    {`
# imports
import pandas as pd 
from pandas_datareader import data 
import altair as alt

def get_data(ticker, start, end):
    """ Download data and add daily returns """
    df = data.get_data_yahoo(ticker, start, end)
    df['returns'] = df['Adj Close'].pct_change()    
    return df

def make_altair_plot(data, title, q=[.05, .95], width=800, height=600):
    """ Generates daily return scatter plot"""
    data = data.reset_index()[['Date', 'returns']]
    returns_chart = alt.Chart(data, width=100).mark_circle(size=8).encode(
        x=alt.X('returns:Q', title='Daily Returns (%)', axis=alt.Axis(format='%'), scale=alt.Scale(domain=[-0.10, 0.10])),
        y=alt.Y('jitter:Q', title=None, axis=None),
        tooltip=[alt.Tooltip('Date:T'), alt.Tooltip('returns:Q', title='Return', format='%')],
        color=alt.Color('returns:Q', scale=alt.Scale(scheme='blueorange'), title='Returns'),
    ).transform_calculate(
        jitter='sqrt(-2*log(random()))*cos(2*PI*random())'
    ).properties(
        width=width, height=height,
        title=title
    ).interactive()
    # make lines
    lines = data.quantile(q)['returns'].values
    overlay = pd.DataFrame({'x1': lines})
    vline = alt.Chart(overlay).mark_rule(color='#01DDFE', strokeWidth=2).encode(x='x1:Q')    
    # assemble the chart
    chart = returns_chart + vline    
    return chart

    # Get data & Plot
    df = get_data('SPY', '1990-01-01', '2019-02-01')
    make_altair_plot(df, 'S&P500 Returns between 1990-2019')    
`}
                   </code>
                  </pre>
                </div>
              </Panel>
            </Collapse>
          </div>
        </Layout>
      </div>
    )  
}
